<template>
  <div class="editable-frame" @keydown.esc="handleEscape" tabindex="0" >
    <div class="header-wrapper">
      <div class="heading-close-icon-wrapper">
        <ar-text
          class="heading"
          size="20px"
          :text="title"
          weight="bold"
          multiple-lines
          :max-lines="1"
          line-height="40px"
        />
        <CloseCrossIcon class="close-icon" @click="handleClose" height="14px" width="14px" data-test-id="editable-close-icon" />
      </div>
      <ar-text
        class="subheading"
        size="14px"
        :text="subtitle"
        weight="normal"
        multiple-lines
        :max-lines="1"
        line-height="2.14"
      />
    </div>
    <div class="content-wrapper">
      <slot />

      <div v-if="blockType === 'trigger'" class="reenrolment-settings">
        <div class="reenrolment-settings__line">
          <ar-text
            size="14px"
            text="Re-enrolment"
            weight="bold"
            :style="{
              color: $arStyle.color.blueGrey800,
            }"
          />
          <am2-switch
            :size="'xs'"
            :value="!isUnique"
            @input="handleUpdateIsUnique"
          />
        </div>
        <ar-text
          size="14px"
          text="Re-enrol contacts if they meet the trigger criteria"
          multiple-lines
          :style="{
            color: $arStyle.color.blueGrey800,
          }" />
      </div>
    </div>
    <div
      v-if="hasSaveButton"
      class="footer-wrapper"
    >
      <ar-simple-button
        text="Save"
        :style="{
          height: '40px',
        }"
        :disabled="saveButtonDisabled"
        @click="handleSave"
        data-test-id="editable-save-button"
      />
    </div>
  </div>
</template>
<script>
import CloseCrossIcon from '~/assets/icons/close-cross-icon.svg?inline'
import {mapActions, mapState} from 'vuex'
export default {
  name: 'EditableFrame',
  components: {
    CloseCrossIcon,
  },
  props: {
    title: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    },
    hasSaveButton: {
      type: Boolean,
      default: false,
    },
    saveButtonDisabled: {
      type: Boolean,
      default: false
    },
    blockType: {
      type: String, // trigger or action
      default: null
    },
  },
  computed: {
    ...mapState({
      isUnique: state => state.automation.isUnique,
      automationId: state => state.automation.id,
    }),
  },
  methods: {
    ...mapActions({
      updateIsUnique: 'automation/UPDATE_IS_UNIQUE',
      fetchAndSetAutomationData: 'automation/FETCH_AND_SET_AUTOMATION',
    }),
    handleEscape() {
      this.$emit('close');
    },
    handleClose() {
      this.$emit('close');
    },
    handleSave() {
      this.$emit('save');
    },
    async handleUpdateIsUnique(value) {
      await this.updateIsUnique({ isUnique: !value, id: this.automationId })
      await this.fetchAndSetAutomationData({id: this.automationId, clearActionsBeforeSet: true, skipSetTriggers: true})
    },
  }
}
</script>
<style lang="scss" scoped>
.editable-frame {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;

  &:focus {
    outline: none;
  }

  .header-wrapper {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 32px 23px 0;

    .heading-close-icon-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .heading {
        pointer-events: none;
      }

      .close-icon {
        color: $skyBlueGrey700;
        cursor: pointer;
      }
   }

    .subheading {
      color: $blueGrey700;
      margin-top: -9px;
      pointer-events: none;
    }
  }

  .content-wrapper {
    flex-grow: 1;
    padding-top: 26px;
    padding: 32px 23px;
    width: 100%;
    overflow: auto;
  }

  .footer-wrapper {
    width: 100%;
    padding: 15px 23px;
    border-top: 1px solid $blueGrey500;
  }

  .reenrolment-settings {
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    &__line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
    }
  }
}
</style>
